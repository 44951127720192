import { useQuery, gql } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import { Spinner } from '../../components/spinner';
import { ItemsSearched } from '../../components/items-searched-pagination';
import * as styles from './index.module.scss';
import { getQueryParams } from '../../helpers/misc';

const GET_RESULTS = gql`
  query($searchTerm: String) {
    posts(where: { search: $searchTerm }, first: 1000) {
      nodes {
          id
          uri
          title
          date
          schemaMarkup {
            eaPostSchemaUploadDate
            eaPostSchemaVideoName
          }
          excerpt
          featuredImage{
            node{
              sourceUrl(size: _1536X1536)
            }
          }
        }
    }
  }
`;

function Search() {
  const [value, setValue] = useState('');

  useEffect(() => {
    const urlParams = getQueryParams();
    if (urlParams) {
      setValue(urlParams.get('value'));
    }
  }, []);

  const { data, loading, error } = useQuery(GET_RESULTS, {
    variables: { searchTerm: value },
  });
  if (loading) {
    return (
      <div className={styles.pageContainer}>
        <Spinner
          className={styles.spinner}
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.pageContainer}>
        <div className={styles.isError}>
          <p>There was an error trying to get the data.</p>
          <p>Try again later.</p>
        </div>
      </div>
    );
  }

  return (
    <ItemsSearched data={data} value={value} />
  );
}

export default Search;
