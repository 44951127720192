/* eslint-disable no-restricted-globals */
import { object, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { Wrap } from '../sections-wrap';
import { ArticleCard } from '../article-card';
import { AskLawyerCard } from '../ask-lawyer-card';
import * as styles from './items-searched.module.scss';
import {
  getQueryParams, saveStateInHistory, isWindowDefined, sortArticlesByDate,
} from '../../helpers/misc';

function ItemsSearched({ data, value }) {
  const [page, setPage] = useState(1);
  const postsPerPage = 8;
  let posts = data?.posts?.nodes;
  const postsLength = posts?.length;
  const numPages = data?.posts?.nodes ? Math.ceil(postsLength / postsPerPage) : 1;
  posts = posts?.slice(page * postsPerPage - postsPerPage, page * postsPerPage);
  useEffect(() => {
    const urlParams = getQueryParams();
    let initialPage = 1;
    if (urlParams) {
      initialPage = Number(urlParams.get('page')) || 1;
      setPage(initialPage);
    }
    saveStateInHistory({ page: initialPage });
  }, []);

  const changePage = (newPage) => {
    if (isWindowDefined) {
      const { origin, pathname } = window.location;
      const urlParams = getQueryParams();
      const valueUri = urlParams.get('value');
      saveStateInHistory({ page });
      setPage(newPage);
      history.pushState(newPage, '', `${origin}${pathname}?value=${valueUri}`);
    }
  };

  const onNavigateByArrows = () => {
    if (history.state?.page) {
      setPage(history.state?.page);
    }
  };

  useEffect(() => {
    if (!history.state?.page) {
      const newValue = page || '';
      saveStateInHistory({ page: newValue });
    }
  }, [history.state]);

  if (isWindowDefined) {
    window.onpopstate = (event) => {
      if (event.state !== null) {
        onNavigateByArrows();
      }
    };
  }

  posts = sortArticlesByDate(posts);

  return (
    <main>
      <title>
        Search - Robinson and Henry
      </title>
      <div className={styles.pageContainer}>
        <Wrap>
          <main className={styles.dataContainer}>
            <header>
              <div className={styles.headerContainer}>
                <h1 className={styles.title}>
                  Search Results for:
                  {' '}
                  <em>{value}</em>
                </h1>

              </div>
              {page > 1 && (
              <h4 className={styles.pageNumber}>
                Page
                {' '}
                {page}
                {' '}
                of
                {' '}
                {numPages}
              </h4>
              )}
            </header>
            <div className={styles.teamGrid}>
              {posts?.map((article, i) => (
                <>
                  {i === 4 && (
                  <AskLawyerCard />
                  )}
                  <ArticleCard
                    key={article.title}
                    title={article.title}
                    uri={article.uri}
                    date={
                      {
                        date: article.date,
                        dateModified: article.schemaMarkup.eaPostSchemaUploadDate,
                        videoName: article.schemaMarkup.eaPostSchemaVideoName,
                      }
                    }
                    wpImage={article?.featuredImage?.node}
                    content={article?.excerpt}
                  />
                </>
              ))}
              <nav className={styles.navigation} aria-label="Posts">
                <div>
                  {page < numPages
                  && (
                  <div className={styles.previous}>
                    <button className={styles.button} type="button" onClick={() => changePage(page + 1)}>
                      Older posts
                    </button>
                  </div>
                  )}
                  {page > 1
                  && (
                  <div className={styles.next}>
                    <button className={styles.button} type="button" onClick={() => changePage(page - 1)}>
                      Newer posts
                    </button>
                  </div>
                  )}
                </div>
              </nav>
            </div>
          </main>
        </Wrap>
      </div>
    </main>
  );
}

ItemsSearched.propTypes = {
  data: object,
  value: string,
};

ItemsSearched.defaultProps = {
  data: {},
  value: '',
};

export { ItemsSearched };
