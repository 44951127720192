import { string } from 'prop-types';
import * as styles from './spinner.module.scss';

function Spinner({ spinnerHeight, spinnerWidth, className = styles.loaderContainer }) {
  return (
    <div className={className}>
      <div
        style={{ height: spinnerHeight, width: spinnerWidth }}
        className={styles.loader}
      />
    </div>
  );
}

Spinner.defaultProps = {
  spinnerWidth: '130px',
  spinnerHeight: '130px',
  className: '',
};
Spinner.propTypes = {
  spinnerHeight: string,
  spinnerWidth: string,
  className: string,
};

export { Spinner };
