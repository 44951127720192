// extracted by mini-css-extract-plugin
export var button = "items-searched-module--button--b9bbe";
export var dataContainer = "items-searched-module--data-container--3ce54";
export var headerContainer = "items-searched-module--header-container--67719";
export var navigation = "items-searched-module--navigation--10e22";
export var next = "items-searched-module--next--552e2";
export var pageContainer = "items-searched-module--page-container--b1b83";
export var pageNumber = "items-searched-module--page-number--96e01";
export var previous = "items-searched-module--previous--791c4";
export var teamGrid = "items-searched-module--team-grid--0bd4e";
export var title = "items-searched-module--title--1615b";